import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "instructor" }
const _hoisted_2 = { class: "instructor__container" }
const _hoisted_3 = { class: "instructor__wrap" }
const _hoisted_4 = { class: "instructor__mobile-container" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "instructor__reviews-wrap" }
const _hoisted_7 = { class: "instructor__value" }
const _hoisted_8 = { class: "instructor__value" }
const _hoisted_9 = { class: "instructor__value" }
const _hoisted_10 = { class: "instructor__value" }
const _hoisted_11 = {
  key: 0,
  class: "instructor__error"
}
const _hoisted_12 = {
  key: 1,
  class: "instructor__code-copy-title"
}
const _hoisted_13 = {
  key: 2,
  class: "instructor__code-copy-wrap"
}
const _hoisted_14 = { class: "instructor__code-copy" }
const _hoisted_15 = { class: "instructor__copy-button" }
const _hoisted_16 = { class: "instructor__controls" }
const _hoisted_17 = {
  key: 0,
  class: "instructor__table-stydents"
}
const _hoisted_18 = { class: "instructor__tabs" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { class: "instructor__container" }
const _hoisted_22 = { class: "instructor__block-analitics" }
const _hoisted_23 = { class: "instructor__block-analitics-value" }
const _hoisted_24 = { class: "instructor__block-analitics" }
const _hoisted_25 = { class: "instructor__block-analitics-value" }
const _hoisted_26 = { class: "instructor__container" }
const _hoisted_27 = { class: "instructor__block-analitics" }
const _hoisted_28 = { class: "instructor__block-analitics-value" }
const _hoisted_29 = { class: "instructor__block-analitics" }
const _hoisted_30 = { class: "instructor__block-analitics-value" }
const _hoisted_31 = { class: "instructor__block-analitics" }
const _hoisted_32 = { class: "instructor__block-analitics-value" }
const _hoisted_33 = { class: "instructor__container instructor__container-second" }
const _hoisted_34 = { class: "instructor__block-analitics instructor__block-analitics-chart" }
const _hoisted_35 = { class: "instructor__chat-line-wrap" }
const _hoisted_36 = ["src"]
const _hoisted_37 = { class: "instructor__block-analitics instructor__block-analitics-chart" }
const _hoisted_38 = { class: "instructor__chat-line-wrap" }
const _hoisted_39 = { class: "instructor__container" }
const _hoisted_40 = { class: "instructor__block-analitics instructor__block-analitics-chart" }
const _hoisted_41 = { class: "instructor__block-analitics-title-block" }
const _hoisted_42 = { class: "instructor__block-analitics-title-faster" }
const _hoisted_43 = { class: "instructor__block-analitics-title-slow" }
const _hoisted_44 = { class: "instructor__chat-cirkle-wrap" }
const _hoisted_45 = { class: "circle-container" }
const _hoisted_46 = { key: 1 }
const _hoisted_47 = ["onClick"]
const _hoisted_48 = { class: "table__body-id" }
const _hoisted_49 = { class: "table__body-little-tr" }
const _hoisted_50 = ["src"]
const _hoisted_51 = { class: "table__body-big-tr" }
const _hoisted_52 = { class: "table__body-big-tr" }
const _hoisted_53 = { class: "table__body-big-tr" }
const _hoisted_54 = { class: "instructor__popup-buttons" }
const _hoisted_55 = { key: 0 }
const _hoisted_56 = {
  key: 1,
  class: "instructor__copied"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowBack = _resolveComponent("ArrowBack")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PhotoMock = _resolveComponent("PhotoMock")!
  const _component_Star = _resolveComponent("Star")!
  const _component_CopyInstructor = _resolveComponent("CopyInstructor")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_DateRangePicker = _resolveComponent("DateRangePicker")!
  const _component_Picture = _resolveComponent("Picture")!
  const _component_AlertBlock = _resolveComponent("AlertBlock")!
  const _component_DefaultPopup = _resolveComponent("DefaultPopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/admin/instructors",
      class: "instructor__back"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ArrowBack)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            (this.instructorData?.image === null)
              ? (_openBlock(), _createBlock(_component_PhotoMock, {
                  key: 0,
                  class: "instructor__photo"
                }))
              : _createCommentVNode("", true),
            (this.instructorData?.image !== null)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: this.instructorData?.image,
                  class: "instructor__photo",
                  alt: ""
                }, null, 8, _hoisted_5))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              class: "instructor__star-wrap",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToMostReviewedInstructors()))
            }, [
              _createTextVNode(_toDisplayString(this.analyticsData.average_rating), 1),
              _createVNode(_component_Star)
            ]),
            _createElementVNode("div", {
              class: "instructor__reviews",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToMostReviewedInstructors()))
            }, _toDisplayString(this.analyticsData.reviews_count) + " Reviews ", 1)
          ]),
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "instructor__title" }, "Full Name", -1)),
          _createElementVNode("div", _hoisted_7, _toDisplayString(this.instructorData?.full_name), 1),
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "instructor__title" }, "Email", -1)),
          _createElementVNode("div", _hoisted_8, _toDisplayString(this.instructorData?.email), 1),
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "instructor__title" }, "Phone Number", -1)),
          _createElementVNode("div", _hoisted_9, _toDisplayString(this.instructorData?.phone?.value), 1),
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "instructor__title" }, "Location", -1)),
          _createElementVNode("div", _hoisted_10, _toDisplayString(this.instructorData?.location), 1),
          (this.generateCode?.code == null)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "instructor__button",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.generateInviteCode && _ctx.generateInviteCode(...args)))
              }, [
                _cache[8] || (_cache[8] = _createTextVNode(" Generate Code ")),
                (this.errorInviteCode)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(this.errorInviteCode), 1))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (this.generateCode?.code != null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, " Generate school code "))
            : _createCommentVNode("", true),
          (this.generateCode?.code != null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, _toDisplayString(this.generateCode?.code), 1),
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_CopyInstructor, { onClick: _ctx.copyText }, null, 8, ["onClick"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_Edit, {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.editInstructor(this.instructorData.id)))
            }),
            _createVNode(_component_Delete, {
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openDeletePoup(this.instructorData.id)))
            })
          ])
        ]),
        (this.instructorData?.students?.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                    _createElementVNode("button", {
                      onClick: ($event: any) => (_ctx.activeTab = index),
                      class: _normalizeClass({ activeTab: _ctx.activeTab === index })
                    }, _toDisplayString(tab.name), 11, _hoisted_19)
                  ]))
                }), 128))
              ]),
              (_ctx.activeTab === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    _createVNode(_component_DateRangePicker, {
                      "select-date-fill": _ctx.selectDateFill,
                      onOnSelectStaticRange: _ctx.selectStaticRange,
                      onCloseCalendar: _ctx.closeCalendar
                    }, null, 8, ["select-date-fill", "onOnSelectStaticRange", "onCloseCalendar"]),
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("div", _hoisted_22, [
                        _cache[13] || (_cache[13] = _createElementVNode("div", null, "OVERALL STUDENTS", -1)),
                        _createElementVNode("div", _hoisted_23, _toDisplayString(this.analyticsData?.overall_students), 1)
                      ]),
                      _createElementVNode("div", _hoisted_24, [
                        _cache[14] || (_cache[14] = _createElementVNode("div", null, "STUDENTS", -1)),
                        _createElementVNode("div", _hoisted_25, _toDisplayString(this.analyticsData?.current_students), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("div", _hoisted_27, [
                        _cache[15] || (_cache[15] = _createElementVNode("div", null, "Number of students moved up", -1)),
                        _createElementVNode("div", _hoisted_28, _toDisplayString(this.analyticsData?.students_moved_up), 1)
                      ]),
                      _createElementVNode("div", _hoisted_29, [
                        _cache[16] || (_cache[16] = _createElementVNode("div", null, "% of students with updated skill", -1)),
                        _createElementVNode("div", _hoisted_30, _toDisplayString(this.analyticsData?.updated_skill_percentage) + "% ", 1)
                      ])
                    ]),
                    _createElementVNode("div", {
                      class: "instructor__container",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.goToMostReviewedInstructors()))
                    }, [
                      _createElementVNode("div", _hoisted_31, [
                        _cache[17] || (_cache[17] = _createElementVNode("div", null, "Reviews", -1)),
                        _createElementVNode("div", _hoisted_32, _toDisplayString(this.analyticsData.reviews_count), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_33, [
                      _createElementVNode("div", _hoisted_34, [
                        _cache[18] || (_cache[18] = _createElementVNode("div", null, "STUDENTS BY LEVEL", -1)),
                        _createElementVNode("div", _hoisted_35, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartDataStudentsByLevel, (item) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: item.id,
                              class: "instructor__chat-line-block"
                            }, [
                              _createElementVNode("img", {
                                src: item.icon_image,
                                alt: ""
                              }, null, 8, _hoisted_36),
                              _createElementVNode("div", {
                                class: "instructor__chat-line",
                                style: _normalizeStyle({ height: item.count * 1 + 'px' })
                              }, null, 4),
                              _createElementVNode("div", null, _toDisplayString(item.count), 1)
                            ]))
                          }), 128))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_37, [
                        _cache[19] || (_cache[19] = _createElementVNode("div", null, "Students by time spent in the program", -1)),
                        _createElementVNode("div", _hoisted_38, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartDataStudentsByTimeToSpent, (item) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: item.id,
                              class: "instructor__chat-line-block instructor__chat-line-time-to-spent-block"
                            }, [
                              _createElementVNode("div", null, _toDisplayString(item.title), 1),
                              _createElementVNode("div", {
                                class: "instructor__chat-line",
                                style: _normalizeStyle({ height: item.count * 1 + 'px' })
                              }, null, 4),
                              _createElementVNode("div", null, _toDisplayString(item.count), 1)
                            ]))
                          }), 128))
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_39, [
                      _createElementVNode("div", _hoisted_40, [
                        _createElementVNode("div", _hoisted_41, [
                          _createElementVNode("div", _hoisted_42, [
                            _cache[20] || (_cache[20] = _createTextVNode(" Fast Learners ")),
                            _createElementVNode("span", null, _toDisplayString(this.analyticsData.fast_learners), 1)
                          ]),
                          _createElementVNode("div", _hoisted_43, [
                            _cache[21] || (_cache[21] = _createTextVNode(" Slow Movers ")),
                            _createElementVNode("span", null, _toDisplayString(this.analyticsData.slow_movers), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_44, [
                          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(1, (item) => {
                            return _createElementVNode("div", {
                              key: item.id,
                              class: "instructor__chat-cirkle-block"
                            }, [
                              _createElementVNode("div", _hoisted_45, [
                                _createElementVNode("div", {
                                  class: "circle",
                                  style: _normalizeStyle(_ctx.circleStyle)
                                }, null, 4)
                              ])
                            ])
                          }), 64))
                        ])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.activeTab === 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
                    _cache[22] || (_cache[22] = _createStaticVNode("<div class=\"table__head\" data-v-42a2eae5><div class=\"table__head-id\" data-v-42a2eae5> ID <div class=\"table__sort-block\" data-v-42a2eae5></div></div><div class=\"table__head-little-tr\" data-v-42a2eae5>Picture</div><div class=\"table__head-big-tr\" data-v-42a2eae5> Full Name <div class=\"table__sort-block\" data-v-42a2eae5></div></div><div class=\"table__head-big-tr\" data-v-42a2eae5>Email</div><div class=\"table__head-big-tr\" data-v-42a2eae5> Phone Number <div class=\"table__sort-block\" data-v-42a2eae5></div></div></div>", 1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.instructorData?.students, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "table__body",
                        key: item,
                        onClick: ($event: any) => (_ctx.goToStydent(item.id))
                      }, [
                        _createElementVNode("div", _hoisted_48, _toDisplayString(item.id), 1),
                        _createElementVNode("div", _hoisted_49, [
                          (item?.image === null)
                            ? (_openBlock(), _createBlock(_component_Picture, { key: 0 }))
                            : _createCommentVNode("", true),
                          (item.image !== null)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 1,
                                src: item.image,
                                alt: "",
                                class: "table__image"
                              }, null, 8, _hoisted_50))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_51, _toDisplayString(item?.full_name), 1),
                        _createElementVNode("div", _hoisted_52, _toDisplayString(item?.email), 1),
                        _createElementVNode("div", _hoisted_53, _toDisplayString(item?.phone?.value), 1)
                      ], 8, _hoisted_47))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.isModalDelete)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 0,
          "on-close": _ctx.handleCloseModalDelete
        }, {
          default: _withCtx(() => [
            _cache[23] || (_cache[23] = _createElementVNode("div", { class: "instructor__popup-title" }, "Are you sure?", -1)),
            _createElementVNode("div", _hoisted_54, [
              _createElementVNode("div", {
                class: "instructor__popup-button-yes",
                onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.deleteInstructor && _ctx.deleteInstructor(...args)))
              }, " Yes "),
              _createElementVNode("div", {
                class: "instructor__popup-button-no",
                onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModalDelete && _ctx.handleCloseModalDelete(...args)))
              }, " No "),
              (this.errorInstructors)
                ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
                    _createVNode(_component_AlertBlock, {
                      message: this.errorInstructors
                    }, null, 8, ["message"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true),
    (_ctx.copied)
      ? (_openBlock(), _createElementBlock("div", _hoisted_56, "Code copied!"))
      : _createCommentVNode("", true)
  ]))
}