
import { defineComponent } from "vue";
import ArrowBack from "@/assets/svg/arrow-back.svg?inline";
import PhotoMock from "@/assets/svg/photo-zaglushka.svg?inline";
import CopyInstructor from "@/assets/svg/copy-instructor.svg?inline";
import Edit from "@/assets/svg/edit-blue.svg?inline";
import Delete from "@/assets/svg/delete-blue.svg?inline";
import DefaultPopup from "@/components/molecules/DefaultPopup.vue";
import Picture from "@/assets/test/picture.svg?inline";
import AlertBlock from "@/components/molecules/AlertBlock.vue";
import DateRangePicker from "@/components/molecules/DateRangePicker.vue";
import Star from "@/assets/svg/star.svg?inline";
// import SortArrow from '@/assets/svg/sort-arrow.svg?inline'
export default defineComponent({
  name: "Instructor",
  data() {
    return {
      isModalDelete: false,
      instructorData: {},
      instructorId: "",
      errorInstructor: null,
      errorInstructors: null,
      generateCode: "",
      errorInviteCode: null,
      copied: false,
      tabs: [
        {
          name: "KPI",
        },
        {
          name: "Students",
        },
        {
          name: "Activity",
        },
      ],
      activeTab: 0,
      chartDataStudentsByLevel: [],
      chartDataStudentsByTimeToSpent: [],
      percentage: 15,
      analyticsData: {},
    };
  },
  components: {
    ArrowBack,
    PhotoMock,
    Edit,
    Delete,
    DefaultPopup,
    Picture,
    AlertBlock,
    CopyInstructor,
    DateRangePicker,
    Star,
    // SortArrow
  },
  mounted() {
    this.instructorId = this.$route.query.instructor;
    this.getInstructor(this.instructorId);
    this.getAnalytics();
  },
  computed: {
    circleStyle() {
      return {
        background: `conic-gradient(#0AC2A3 ${
          this.percentage * 3.6
        }deg, #0066cc 0deg)`,
      };
    },
  },
  methods: {
    openDeletePoup(id) {
      this.idInstructor = id;
      this.isModalDelete = true;
    },
    handleCloseModalDelete() {
      this.isModalDelete = false;
      this.errorInstructors = null;
    },
    deleteInstructor() {
      this.$store
        .dispatch("instructors/deleteInstructor", this.idInstructor)
        .then(
          () => {
            this.idInstructor = null;
            this.$router.push("/admin/instructors");
          },
          (error) => {
            this.errorInstructors = error.response.data;
          }
        );
    },
    editInstructor(id) {
      this.$router.push("/admin/create-instructors?edit=true&instructor=" + id);
    },
    getInstructor(id) {
      this.$store.dispatch("instructors/getInstructor", id).then(
        (res) => {
          this.instructorData = res.data;
        },
        (error) => {
          this.errorInstructor = error.response.data.errors;
        }
      );
    },
    getAnalytics(params, sort) {
      const data = {
        id: this.instructorId,
        params: "",
      };
      let searchParam = `?page=${this.page}&n=20&search=`;
      let startDate = "";
      let endDate = "";

      const currentDate = new Date();
      const pastDate = new Date();
      if (this.period === 1) {
        pastDate.setDate(currentDate.getDate() - 7);
      } else if (this.period === 2) {
        pastDate.setDate(currentDate.getDate() - 30);
      } else if (this.period === 3) {
        pastDate.setDate(currentDate.getDate() - 1000);
      }

      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      if (sort === undefined) {
        sort = "";
      }

      startDate =
        this.selectedStartDate !== undefined
          ? this.selectedStartDate
          : formatDate(pastDate);
      endDate =
        this.selectedEndDate !== undefined
          ? this.selectedEndDate
          : formatDate(currentDate);

      data.params =
        searchParam +
        "&n=20&sort=" +
        sort +
        this.filters +
        this.filtersDate +
        `&start_date=${startDate}` +
        `&end_date=${endDate}`;

      this.$store.dispatch("analytics/getInstructorKpi", data).then(
        (res) => {
          this.analyticsData = res.data;
          this.analyticsMeta = res.meta;
          this.chartDataStudentsByLevel = this.analyticsData.students_by_level;
          this.chartDataStudentsByTimeToSpent =
            this.analyticsData.students_by_time_spent;
          this.percentage =
            (this.analyticsData.slow_movers /
              this.analyticsData.fast_learners) *
            50;
        },
        (error) => {
          this.erroranalytics = error.response.data.errors;
        }
      );
    },
    generateInviteCode(data) {
      data = [];
      data.push(this.instructorId);
      this.$store.dispatch("instructors/generateInviteCode", data).then(
        (res) => {
          this.generateCode = res.data;
        },
        (error) => {
          this.errorInviteCode = error.response.data.message;
        }
      );
    },
    copyText() {
      const textToCopy = this.generateCode.code;
      const textarea = document.createElement("textarea");
      textarea.value = textToCopy;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      this.copied = true;
      setTimeout(() => (this.copied = false), 3000);
    },
    goToStydent(id) {
      this.$router.push(`/admin/student?student=${id}`);
    },
    selectDateFill(date, month, year) {
      console.log("aaaa", date);
      const selectedDate = new Date(year, month, date.value);
      const currentDate = new Date();
      if (selectedDate > currentDate) {
        return;
      }
      if (date.value !== "") {
        const selectedDate = new Date(year, month, date.value);
        if (!this.selectedStartDate) {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
        } else if (
          !this.selectedEndDate &&
          selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate() >=
            this.selectedStartDate
        ) {
          this.selectedEndDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
        } else {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
          this.selectedEndDate = null;
        }
      }
      if (this.selectedEndDate) {
        this.filtersDate = `&start_date=${this.selectedStartDate}&end_date=${this.selectedEndDate}`;
        this.getAnalytics();
      }
    },
    selectStaticRange(period) {
      this.period = period;
      this.getAnalytics();
    },
    closeCalendar() {
      this.isCustomPeriodsCalendarVisible = false;
      this.isCalendarVisible = false;
    },
    goToMostReviewedInstructors() {
      this.$router.push(
        `/admin/analytics/instructor/most-reviewed-instructors?instructor=${this.instructorId}`
      );
    },
  },
});
